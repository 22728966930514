<template>
  <div class="row p-0 m-0">
    <div class="col-12 d-flex p-0 justify-content-start">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des applications
      </span>

      <CustomAddButton
        text="Nouvelle application"
        :disabled="disabled || (!applicationsList.length ? true : false)"
        @click="addItem"
      />
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <!-- {{ APPLICATIONS }} -->
    <!-- {{ this.COMPOSANT.applications }} -->
    <div class="col-12 p-0">
      <div class="card-body p-0">
        <b-table
          :items="tableItems"
          :fields="fields"
          id="table-list"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          bordered
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="!disabled ? ['delete'] : []"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item.id)"
              @deleteItem="deleteItem(data.item.id)"
              @cancelItem="cancelItem(data.item)"
              @confirmItem="confirmItem(data.item)"
            />
          </template>

          <template v-slot:cell(ref)="data">
            <b-link
              :to="{
                name: 'show-application',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              >SA-{{ data.item.id }}</b-link
            >
          </template>

          <template v-slot:cell(name)="data">
            <!-- <span>{{ applicationsList.map((app) => app["@id"]) }}</span> -->
            <b-link
              :to="{
                name: 'show-application',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              v-if="!data.item.hasOwnProperty('editMode')"
              >{{ data.value }}</b-link
            >
            <v-select
              :options="applicationsList"
              v-else
              label="name"
              :reduce="(application) => application['@id']"
              placeholder="libelle"
              v-model="data.item.name"
              appendToBody
              style="min-width: max-content"
              @input="getApplicationData(data.item)"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>
          <template v-slot:cell(capaciteSis)="data">
            <b-badge
              v-if="data.value.length"
              style="
                cursor: pointer;
                background-color: #adb5bd;
                border-color: #adb5bd;
              "
              pill
              @click="showCapacites(data.item)"
              >{{ data.value.length || 0 }}</b-badge
            >
            <b-badge
              v-else
              style="
                cursor: pointer;
                background-color: #adb5bd;
                border-color: #adb5bd;
              "
              pill
              >{{ data.value.length }}</b-badge
            >
          </template>

          <template v-slot:cell(po)="data">
            <TableResponsable
              :responsable="data.value"
              v-if="!data.item.hasOwnProperty('editMode')"
            />
            <v-select
              v-model="selectedUser"
              :value="data.value['@id']"
              :options="usersList"
              placeholder="po"
              label="text"
              appendToBody
              style="min-width: max-content"
              :reduce="(user) => user.value"
              v-else
              disabled
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>

          <template v-slot:cell(status)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <v-select
              v-else
              appendToBody
              disabled
              placeholder="Statut"
              style="min-width: max-content"
              v-model="data.item.status"
              :options="['En cours', 'En prod']"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>

          <template v-slot:cell(taux)="data">
            <!-- <simple-slider  >{{data.value}}</simple-slider> -->
            <!-- v-if="!data.item.hasOwnProperty('editMode')" -->

            <b-progress
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :value="data.value || 0"
                :label="`${data.value || 0}%`"
                :style="`background-color: ${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
              ></b-progress-bar>
            </b-progress>
            <!-- <section v-else class="simple-slider">
              <vue-slide-bar class="pt-3" v-model="data.item.taux" />
            </section> -->
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
    <div v-if="showCapacitesModule" to="modals-xyz-548">
      <Modal @close="showCapacitesModule = !showCapacitesModule">
        <ApplicationCapacitesList :application="selectedApplication" />
      </Modal>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
// import VueSlideBar from "vue-slide-bar";
import TableResponsable from "@/components/custom/TableResponsable";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators"; //validation
import CustomAddButton from "@/components/custom/CustomAddButton";
import Modal from "@/components/custom/Modal.vue";
import ApplicationCapacitesList from "./ApplicationCapacitesList.vue";
import Swal from "sweetalert2";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableActions, // VueSlideBar,
    TableResponsable,
    CustomAddButton,
    ApplicationCapacitesList,
    Modal,
  },

  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "ref", label: "Ref SA", sortable: true },
        { key: "name", label: "Libellé", sortable: true },
        { key: "capaciteSis", label: "Capacite SI", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
      selectedUser: null,
      selectedApplication: null,
      showCapacitesModule: false,
    };
  },
  validations: {
    tableItems: {
      $each: {
        name: {
          required,
        },
        status: {
          required,
        },
      },
    },
    selectedUser: {
      required,
    },
  },
  methods: {
    editItem(id) {
      this.$store
        .dispatch("composant/fetchComposantApplications", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((item) => {
            if (id === item.id && item.po) this.selectedUser = item.po["@id"];
            return id === item.id
              ? {
                  ...item,
                  editMode: true,
                }
              : item;
          });
        });
    },
    cancelItem(item) {
      if (item.name === "" && item.po === "" && item.status === null)
        this.$store.dispatch(
          "composant/fetchComposantApplications",
          this.$route.params.id
        );
      else (item.name = ""), (item.po = ""), (item.status = null);
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          let applications = this.APPLICATIONS.applications
            .filter((app) => app.id !== id)
            .map((application) => application["@id"]);
          this.$store
            .dispatch("composant/updateComposant", {
              id: this.$route.params.id,
              applications: applications,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "composant/fetchComposantApplications",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        delete item.editMode;

        if (item.id) {
          let applications = this.APPLICATIONS.applications.map(
            (application) => application["@id"]
          );
          applications.push(item["@id"]);
          this.$store
            .dispatch("composant/updateComposant", {
              id: this.$route.params.id,
              applications: applications,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "composant/fetchComposantApplications",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("L'application est mise à jour !", "", "success");
                });
            });
        }
      }
    },
    addItem() {
      if (
        !this.tableItems.some((item) => (item.editMode ? item.editMode : false))
      ) {
        this.tableItems = [
          {
            editMode: true,
          },
          ...this.tableItems,
        ];
        this.selectedUser = null;
        this.currentPage = 1;
      }
    },
    getApplicationData(item) {
      if (item.name) {
        const application = this.APPLICATIONS_LIST.find(
          (application) => application["@id"] === item.name
        );
        if (application) {
          window.console.log(application);
          item.id = application.id;
          item.status = application.status;
          item.taux = application.taux;
          item["@id"] = application["@id"];
          this.selectedUser = application.po["@id"];
        }
      } else {
        item.status = null;
      }
    },
    showCapacites: function (application) {
      (this.selectedApplication = application),
        (this.showCapacitesModule = true);
    },
  },
  props: {
    applications: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.tableItems = this.applications;
  },
  created() {
    if (this.$route.params.id) {
      this.$store
        .dispatch("composant/fetchComposantApplications", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
      this.$store.dispatch("users/fetchAllResponsables");
      this.$store.dispatch("application/fetchAllApplications");
    } else {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("composant", ["APPLICATIONS", "COMPOSANT"]), //get all status
    ...mapGetters("application", {
      APPLICATIONS_LIST: "APPLICATIONS",
    }),
    usersList() {
      return this.RESPONSABLES.map((user) => ({
        text: `${user.firstname} ${user.lastname}`,
        value: `/api/users/${user.id}`,
      }));
    },
    applicationsList() {
      return this.APPLICATIONS_LIST.filter(
        (app) =>
          !this.APPLICATIONS.applications.find(
            (application) => app.id === application.id
          )
      );
    },
    rows() {
      return this.tableItems ? this.tableItems.length : 0;
    },
  },
  watch: {
    APPLICATIONS() {
      this.tableItems = this.APPLICATIONS.applications;
    },
  },
};
</script>
