<template>
  <div class="row p-0 m-0">
    <div class="col-12 d-flex p-0 justify-content-start">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des transformationss
      </span>

      <CustomAddButton
        text="Nouvelle Transformation"
        :disabled="disabled"
        @click="addItem"
      />
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="col-12 p-0">
      <div class="card-body p-0">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          :busy="loading"
          bordered
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(fdr)="data">
            <b-link
              :to="{
                name: 'show-fdr',
                params: {
                  id: data.item.initiative.feuilleRoute.id,
                  slug: data.item.initiative.feuilleRoute.slug || 'abcde',
                },
              }"
              v-if="!data.item.hasOwnProperty('editMode')"
              >{{ data.item.initiative.feuilleRoute.name }}</b-link
            >
            <div v-else>
              <v-select
                v-model="fdr"
                label="text"
                :reduce="(fdr) => fdr.value"
                :options="fdrsList"
                placeholder="fdr"
                appendToBody
                :disabled="data.item.id ? true : false"
                @input="initiative = null"
                style="min-width: max-content; padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0': $v.fdr.$error && $v.fdr.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.fdr.$dirty"
              >
                {{ !$v.fdr.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>

          <template v-slot:cell(initiative)="data">
            <b-link
              :to="{
                name: 'show-initiative',
                params: {
                  id: data.value.id,
                  slug: data.value.slug || 'abcde',
                },
              }"
              v-if="!data.item.hasOwnProperty('editMode')"
              >{{ data.value.libelle }}</b-link
            >
            <div v-else>
              <v-select
                appendToBody
                :disabled="data.item.id ? true : false"
                v-model="initiative"
                label="text"
                :reduce="(initiative) => initiative.value"
                placeholder="initiative"
                :options="initiativesList"
                style="min-width: max-content; padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.initiative.$error && $v.initiative.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.initiative.$dirty"
              >
                {{ !$v.initiative.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>

          <template v-slot:cell(responsable)="data">
            <TableResponsable
              :responsable="data.item.initiative.responsable"
              v-if="!data.item.hasOwnProperty('editMode')"
            />
            <!-- <span v-if="!data.item.hasOwnProperty('editMode')">{{ data.value }}</span> -->
            <v-select
              type="text"
              placeholder="Responsable"
              v-else
              disabled
              appendToBody
              style="min-width: max-content"
              v-model="responsable"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>
          <template v-slot:cell(name)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">{{
              data.value
            }}</span>
            <div v-else>
              <b-form-input
                class="text"
                placeholder="Libellé"
                v-model="name"
                :class="{
                  'is-invalid ': $v.name.$error && $v.name.$dirty,
                }"
              />
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.name.$dirty"
              >
                {{ !$v.name.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(patrimoine)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">{{
              data.item.initiative.feuilleRoute.patrimoine.name
            }}</span>
            <v-select
              v-model="patrimoine"
              disabled
              appendToBody
              placeholder="Patrimoine"
              style="min-width: max-content"
              v-else
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>
          <template v-slot:cell(forceImpact)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">{{
              data.value
            }}</span>
            <div v-else>
              <v-select
                :options="['Très faible', 'Faible', 'Moyen', 'Elévé', '']"
                v-model="forceImpact"
                placeholder="Force Impact"
                appendToBody
                style="min-width: max-content; padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.forceImpact.$error && $v.forceImpact.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.forceImpact.$dirty"
              >
                {{ !$v.forceImpact.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>

          <template #cell(actions)="data">
            <table-actions
              :actions="!disabled ? ['edit', 'delete'] : []"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item.id)"
              @cancelItem="cancelItem(data.item)"
              @confirmItem="confirmItem(data.item)"
            />
          </template>
          <template v-slot:cell(type)="data">
            <div v-if="!data.item.hasOwnProperty('editMode')">
              <b-badge v-if="data.value === 'Création'" variant="success">{{
                data.value
              }}</b-badge>
              <b-badge
                variant="danger"
                v-else-if="data.value === 'Suppression'"
                >{{ data.value }}</b-badge
              >
              <b-badge
                variant="warning"
                v-else-if="data.value === 'Evolution'"
                >{{ data.value }}</b-badge
              >
              <b-badge variant="secondary" v-else>{{ data.value }}</b-badge>
            </div>
            <div v-else>
              <v-select
                appendToBody
                v-model="type"
                style="min-width: max-content; padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.type.$error && $v.type.$dirty,

                  ' bg-success text-white': type === 'Création',
                  ' bg-danger text-white': type === 'Suppression',
                  ' bg-warning': type === 'Evolution',
                }"
                :options="['Création', 'Suppression', 'Evolution']"
                placeholder="Type"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.type.$dirty"
              >
                {{ !$v.type.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import TableResponsable from "@/components/custom/TableResponsable";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { required } from "vuelidate/lib/validators"; //validation

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions, TableResponsable, CustomAddButton },
  data: function () {
    return {
      loading: true,
      sortBy: "",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "fdr", label: "Feuille de route", sortable: true },
        { key: "initiative", label: "Initiative", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "patrimoine", label: "Patrimoine", sortable: true },
        { key: "type", label: "Type impact", sortable: true },
        { key: "name", label: "Libellé impact", sortable: true },
        { key: "forceImpact", label: "Force impact", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
      type: "",
      patrimoine: "",
      fdr: null,
      initiative: null,
      responsable: "",
      name: "",
      forceImpact: "",
    };
  },
  validations: {
    name: {
      required,
    },
    forceImpact: {
      required,
    },
    type: {
      required,
    },
    initiative: {
      required,
    },
    fdr: {
      required,
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store
        .dispatch(
          "composant/fetchComposantTransformations",
          this.$route.params.id
        )
        .then(() => {
          this.loading = false;
        });
      this.$store.dispatch("fdr/fetchAllFdrs");
    } else {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters("composant", ["TRANSFORMATION"]),
    ...mapGetters("fdr", ["FDRS"]),

    fdrsList() {
      return this.FDRS.filter(({ initiatives }) => initiatives.length).map(
        (fdr) => ({
          value: fdr["@id"],
          text: fdr.name,
        })
      );
    },
    initiativesList() {
      if (this.fdr)
        return this.FDRS.find((fdr) => fdr["@id"] === this.fdr).initiatives.map(
          (initiative) => ({
            value: initiative["@id"],
            text: initiative.libelle,
          })
        );
      return [];
    },
    rows() {
      return this.tableItems.length;
    },
  },
  methods: {
    addItem() {
      this.tableItems = !this.tableItems.some((item) =>
        item.editMode ? item.editMode : false
      )
        ? [
            {
              editMode: true,
            },
            ...this.tableItems,
          ]
        : this.tableItems;
      this.currentPage = 1;
    },
    editItem(item) {
      this.$store
        .dispatch(
          "composant/fetchComposantTransformations",
          this.$route.params.id
        )
        .then(() => {
          this.tableItems = this.tableItems.map((challenge) => {
            if (item.id === challenge.id && challenge.po)
              this.selectedUser = challenge.po["@id"];
            return item.id === challenge.id
              ? {
                  ...challenge,
                  editMode: true,
                }
              : challenge;
          });
          this.initiative = item.initiative["@id"];
          this.fdr = item.initiative.feuilleRoute["@id"];
          this.patrimoine = item.initiative.feuilleRoute.patrimoine.name;
          this.responsable =
            item.initiative.responsable.firstname +
            " " +
            item.initiative.responsable.lastname;
          this.type = item.type;
          this.name = item.name;
          this.forceImpact = item.forceImpact;
        });
    },
    cancelItem(item) {
      if (!item.id) {
        if (
          this.name === "" &&
          this.type === "" &&
          this.forceImpact === "" &&
          this.fdr === null
        )
          this.$store.dispatch(
            "composant/fetchComposantTransformations",
            this.$route.params.id
          );
        else {
          this.name = "";
          this.type = "";
          this.forceImpact = "";
          this.fdr = null;
          this.initiative = null;
        }
      } else {
        if (this.name === "" && this.type === "" && this.forceImpact === "") {
          this.$store
            .dispatch(
              "composant/fetchComposantTransformations",
              this.$route.params.id
            )
            .then(() => {
              this.fdr = null;
              this.initiative = null;
            });
        } else {
          this.name = "";
          this.type = "";
          this.forceImpact = "";
        }
      }
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("impact/deleteImpact", id).then(() => {
            this.$store
              .dispatch(
                "composant/fetchComposantTransformations",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        delete item.editMode;

        if (item.id)
          this.$store
            .dispatch("impact/updateImpact", {
              id: item.id,
              name: this.name,
              type: this.type,
              forceImpact: this.forceImpact,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "composant/fetchComposantTransformations",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("L'impact est mis à jour !", "", "success");
                  this.name = "";
                  this.type = "";
                  this.forceImpact = "";
                  this.fdr = null;
                  this.initiative = null;
                });
            });
        else
          this.$store
            .dispatch("impact/createImpact", {
              name: this.name,
              type: this.type,
              forceImpact: this.forceImpact,
              initiative: this.initiative,
              composant: this.TRANSFORMATION["@id"],
            })
            .then(() => {
              this.$store
                .dispatch(
                  "composant/fetchComposantTransformations",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("L'impact est bien créé !", "", "success");
                  this.name = "";
                  this.type = "";
                  this.forceImpact = "";
                  this.fdr = null;
                  this.initiative = null;
                });
            });
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    TRANSFORMATION() {
      this.tableItems = this.TRANSFORMATION.impacts;
    },

    initiative() {
      if (this.initiative) {
        var selectedFdr = this.FDRS.find((fdr) => fdr["@id"] === this.fdr);

        var selectedInitiative = selectedFdr.initiatives.find(
          (initiative) => initiative["@id"] === this.initiative
        );
        this.patrimoine = selectedFdr.patrimoine.name;
        this.responsable =
          selectedInitiative.responsable.firstname +
          " " +
          selectedInitiative.responsable.lastname;
      } else {
        this.patrimoine = "";
        this.responsable = "";
      }
    },
  },
};
</script>

