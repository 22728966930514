var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-0 m-0"},[_c('div',{staticClass:"col-12 d-flex p-0 justify-content-start"},[_c('span',{staticClass:"h4 text-secondary mr-auto my-auto"},[_vm._v("Liste des versions ")]),_c('CustomAddButton',{attrs:{"text":"Nouvelle version","disabled":_vm.disabled},on:{"click":_vm.addInput}})],1),_vm._m(0),_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"card-body p-0"},[_c('b-table',{attrs:{"items":_vm.tableItems,"id":"table-list","responsive":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"show-empty":"","bordered":"","busy":_vm.loading,"striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":!_vm.disabled ? ['edit', 'delete'] : [],"editMode":data.item.hasOwnProperty('editMode')},on:{"editItem":function($event){return _vm.editItem(data.item)},"deleteItem":function($event){return _vm.deleteItem(data.item.id)},"cancelItem":function($event){return _vm.cancelItem(data.item)},"confirmItem":function($event){return _vm.confirmItem(data.item)}}})]}},{key:"cell(name)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_c('b-form-input',{class:{
                'is-invalid': _vm.$v.name.$error && _vm.$v.name.$dirty,
              },attrs:{"type":"text","placeholder":"version .."},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(_vm.$v.name.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.name.required ? "champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(description)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(" "+_vm._s(data.value || "-")+" ")]):_c('b-form-input',{attrs:{"type":"text","placeholder":"description .."},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}},{key:"cell(status)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[(
                data.value === 'En cours' &&
                !data.item.hasOwnProperty('editMode')
              )?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(_vm._s(data.value))]):(
                data.value === 'Obsolete' &&
                !data.item.hasOwnProperty('editMode')
              )?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(_vm._s(data.value))]):_vm._e()],1):_c('div',{staticClass:"row m-0",attrs:{"id":"add-page"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
                'is-invalid': _vm.$v.status.$error && _vm.$v.status.$dirty,
              },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{class:{
                  ' bg-success text-white': _vm.status === 'En cours',
                  ' bg-danger text-white': _vm.status === 'Obsolete',
                },style:({
                  minWidth: 'max-content',
                }),attrs:{"placeholder":"Statut","appendToBody":"","options":['En cours', 'Obsolete']},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),(_vm.$v.status.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.status.required ? "champ obligatoire" : "")+" ")]):_vm._e()])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
}]

export { render, staticRenderFns }