<template>
  <div class="row p-0 m-0">
    <div class="col-12 d-flex p-0 justify-content-start">
      <span class="h4 text-secondary mr-auto my-auto">Liste des versions </span>

      <CustomAddButton
        text="Nouvelle version"
        :disabled="disabled"
        @click="addInput"
      />
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="col-12 p-0">
      <div class="card-body p-0">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          bordered
          :busy="loading"
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="!disabled ? ['edit', 'delete'] : []"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item.id)"
              @cancelItem="cancelItem(data.item)"
              @confirmItem="confirmItem(data.item)"
            />
          </template>

          <template v-slot:cell(name)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <b-form-input
                :class="{
                  'is-invalid': $v.name.$error && $v.name.$dirty,
                }"
                v-model="name"
                type="text"
                placeholder="version .."
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.name.$dirty"
              >
                {{ !$v.name.required ? "champ obligatoire" : "" }}
              </span>
              <!--  -->
            </div>
          </template>

          <template v-slot:cell(description)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value || "-" }}
            </span>
            <b-form-input
              type="text"
              placeholder="description .."
              v-else
              v-model="description"
            ></b-form-input>
          </template>

          <template v-slot:cell(status)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              <b-badge
                variant="success"
                v-if="
                  data.value === 'En cours' &&
                  !data.item.hasOwnProperty('editMode')
                "
                >{{ data.value }}</b-badge
              >
              <b-badge
                variant="danger"
                v-else-if="
                  data.value === 'Obsolete' &&
                  !data.item.hasOwnProperty('editMode')
                "
                >{{ data.value }}</b-badge
              ></span
            >

            <div class="row m-0" id="add-page" v-else>
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid': $v.status.$error && $v.status.$dirty,
                }"
              >
                <v-select
                  v-model="status"
                  placeholder="Statut"
                  :style="{
                    minWidth: 'max-content',
                  }"
                  :class="{
                    ' bg-success text-white': status === 'En cours',
                    ' bg-danger text-white': status === 'Obsolete',
                  }"
                  appendToBody
                  :options="['En cours', 'Obsolete']"
                >
                </v-select>
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.status.$dirty"
              >
                {{ !$v.status.required ? "champ obligatoire" : "" }}
              </span>
            </div>
            <!--  -->
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators"; //validation
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions, CustomAddButton },

  data() {
    return {
      color: "",
      sortBy: "dd",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "name", label: "Version", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "status", label: "Status Version", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
      loading: true,
      name: "",
      description: "",
      status: "",
    };
  },
  validations: {
    name: {
      required,
    },

    status: {
      required,
    },
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeInputCOlor(e) {
      let el = e.target;
      const value = el.options[el.options.selectedIndex].value;
      if (value === "En Cours") {
        el.classList.add("primary");
        el.classList.remove("danger");
        el.style.color = "#fff";
      } else if (value === "Obsolete") {
        el.classList.add("danger");
        el.classList.remove("primary");
        el.style.color = "#fff";
      }
    },
    addInput() {
      this.tableItems = !this.tableItems.some((item) =>
        item.editMode ? item.editMode : false
      )
        ? [
            {
              editMode: true,
              // actions: ["confirm", "cancel"],
            },
            ...this.tableItems,
          ]
        : this.tableItems;
      this.currentPage = 1;
    },
    editItem(item) {
      this.$store
        .dispatch("composant/fetchComposantVersions", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((challenge) => {
            return challenge.id === item.id
              ? {
                  ...challenge,
                  editMode: true,
                }
              : challenge;
          });
          this.name = item.name;
          this.description = item.description;
          this.status = item.status;
        });
    },
    cancelItem() {
      if (this.name === "" && this.description === "" && this.status === null)
        this.$store.dispatch(
          "composant/fetchComposantVersions",
          this.$route.params.id
        );
      else (this.name = ""), (this.description = ""), (this.status = null);
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("version/deleteVersion", id).then(() => {
            this.$store
              .dispatch(
                "composant/fetchComposantVersions",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // delete item.editMode;

        if (!item.id)
          this.$store
            .dispatch("version/createVersion", {
              name: this.name,
              description: this.description,
              status: this.status,
              composant: this.VERSIONS["@id"],
            })
            .then(() => {
              this.$store
                .dispatch(
                  "composant/fetchComposantVersions",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire({
                    title: "La version est bien créée !",
                    type: "success",
                    confirmButtonText: "OK",
                  });
                  this.name = "";
                  this.description = "";
                  this.status = "";
                });
            });
        else
          this.$store
            .dispatch("version/updateVersion", {
              id: item.id,
              name: this.name,
              description: this.description,
              status: this.status,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "composant/fetchComposantVersions",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("La version est mise à jour !", "", "success");
                });
            });
      }
    },
  },
  created() {
    if (this.$route.params.id)
      this.$store
        .dispatch("composant/fetchComposantVersions", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    else {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters("composant", ["VERSIONS"]), //get all status

    rows() {
      return this.tableItems.length;
    },
  },

  watch: {
    VERSIONS() {
      this.tableItems = this.VERSIONS.versions;
    },
  },
};
</script>

<style scoped>
.primary {
  background-color: #2a8947;
}

.danger {
  background-color: #c5221e;
}
</style>
>
